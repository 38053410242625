@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Poppins:ital,wght@0,300;0,400;1,100&display=swap');

@font-face {
  font-family: 'General Sans Bold';
  src: url('fonts/GeneralSans-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'General Sans Regular';
  src: url('fonts/GeneralSans-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

*,.MuiTypography-root {
  font-family: 'General Sans Regular', 'Poppins';
  margin: 0;
  text-decoration: none;
  background-color: "#f8f9fc";
  list-style-type: none;
  list-style: none;
}

